<template>
    <div class="order-list py-5 pb-lg-6">
        <section class="">
            <div class="row g-3">
                <div class="col-12">
                    <button @click="$router.go(-1)" type="button" class="btn btn-light"> <i class="mdi mdi-arrow-left"></i> Back</button> 
                </div>
                <div class="col-lg-12">
                    <order-table />
                </div>
            </div>
        </section>
    </div>
</template>
<script>

import OrderTable from "@/components/order/OrderTable.vue"

export default {
    name: 'order-list',
    components:{
        OrderTable
    },
}
</script>
